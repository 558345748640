function getEguildIdAsync() {
    if (typeof uicontroller === 'undefined' || typeof uicontroller.GetPharmacyEGuildID !== "function") {
        console.log("uicontroller.GetPharmacyEGuildID not defined");
        return null;
    }
    else {
        return uicontroller.GetPharmacyEGuildID();
    }
}

function getIdentityTokenAsync() {
    if (typeof uicontroller === 'undefined' || typeof uicontroller.GetMedAdvisorIdentityToken !== "function") {
        console.log("uicontroller.GetMedAdvisorIdentityToken not defined");
        return null;
    }
    else {
        return uicontroller.GetMedAdvisorIdentityToken();
    }
}

function getAccessTokenAsync()
{
    if (typeof uicontroller === 'undefined' || typeof uicontroller.GetMedAdvisorAuthToken !== "function") {
        console.log("uicontroller.GetMedAdvisorAuthToken not defined");
        return null;
    }
    else {
        return uicontroller.GetMedAdvisorAuthToken();
    }
}


function isInPlusOne() {
    return typeof uicontroller !== 'undefined';
}
